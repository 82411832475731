import Vue from "vue";
import { API } from "../../../services/API";
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../../services/funcoes";

function initialState() {
  return {

    dialogFiltroAvancado  : false,
    dialogUnidades        : false,

    dados       : [],
    paginas     : 0,
    filtro: {
      // Filtro na Tela de Consulta --------
      cliente_nome        : null,
      cod_empreendimento  : null,
      empreendimento      : null,

      quadra              : null,
      lote                : null,
      num_proposta        : null,

      cod_pessoa_conta    : null,
      pessoa_conta_nome   : null,
      parc_valor_cnab     : null,

      // Vencimento (data e valor)
      parc_dt_inicial       : null,
      parc_dt_final         : null,
      parc_valor_inicial    : null,
      parc_valor_final      : null,
  
      // Pagamento (data e valor)
      parc_dt_pagto_inicial     : null,
      parc_dt_pagto_final       : null,
      parc_valor_pagto_inicial  : null,
      parc_valor_pagto_final    : null,

      // Filtro Avancado --------------------
      situacao: null,
      proprietario: null,
      contrato: null,
      num_cobranca: null,
      tipo_parcela: null,
      parcela: null,
      tipo_impressao: null,
      bloqueados: null,
      reajustados: null,
      proposta_inicial: null,
      proposta_final: null,
      data_contrato: null,
      impressao_inicial: null,
      impressao_final: null,
    }
  }
}

var vm_store_ContasReceber = new Vue({

  data () {
    return initialState();
  },

  methods: {

    // routes.put('/api/erp/financeiro/contasreceber_editar/:cod_empreendcobranca', AuthController.authenticated, ContasReceberController.contasreceber_editar);
    // routes.put('/api/erp/contas_a_receber/contasreceber_estornar/:cod_empreendcobranca', AuthController.authenticated, ContasReceberController.contasreceber_estornar);
    // routes.put('/api/erp/contas_a_receber/contasreceber_pagar/:cod_empreendcobranca', AuthController.authenticated, ContasReceberController.contasreceber_pagar);
    // routes.put('/api/erp/contas_a_receber/contasreceber_excluir/:cod_empreendcobranca', AuthController.authenticated, ContasReceberController.contasreceber_excluir);   

    async ContasReceberNovo(body){
      const lo_Param = JSON.stringify(body)
      console.log('lo_Param',lo_Param)
      const resposta = await API.post(`/erp/contasreceber/contasreceber_novo`, {params: lo_Param});
      return resposta;
    },
    
    async ContasReceberEstornar(body) {
      const resposta = await API.put(`/erp/contasreceber/contasreceber_estornar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasReceberPagar(body){
      const resposta = await API.put(`/erp/contasreceber/contasreceber_pagar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasReceberEditar(body){
      const resposta = await API.put(`/erp/contasreceber/contasreceber_editar/${body.cod_empreendcobranca}`, JSON.stringify(body));
      return resposta;
    },

    async ContasReceberExcluir(body){
      const resposta = await API.put(`/erp/contasreceber/contasreceber_excluir/${body}`);
      return resposta;
    },

    async ContasReceberImpressos(item) {
      console.log('store', item)
      const lo_Param = {cod_empreendcobranca: item}
      const resposta = await API.get(`/erp/contasreceber/contasreceber_impressos`, {params: lo_Param});
      return resposta;
    },

    async ContasReceberImpressosExcluir(item) {
      console.log('store', item)
      const lo_Param = {cod_empreendcobranca_impressao: item}
      const resposta = await API.put(`/erp/contasreceber/contasreceber_impressos_excluir`, {params: lo_Param});
      return resposta;
    },

    async ContasReceberImpressosBaixar(item) {
      const lo_Param = {cod_empreendcobranca_impressao: item}
      const resposta = await API.put(`/erp/contasreceber/contasreceber_impressos_baixar`, {params: lo_Param});
      return resposta;
    },

    async ContasReceberBuscaProposta(item) {
      const lo_Param = {cod_empreendvenda: item}
      const resposta = await API.get(`/erp/contasreceber/contasreceber_proposta_buscar`, {params: lo_Param});
      return resposta;
    },
    
    resetData() { 
      Object.assign(this.$data, initialState());
    },

      
  },

})

export default vm_store_ContasReceber
