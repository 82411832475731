import { API } from "./API";

const ContasReceberGet = async (params) => {
  var ls_param = {params: {
                          ...params
                          },
                 }
  const resposta = await API.get("erp/contasreceber", ls_param);
  var ls_Resp = [];
  if (resposta)
    if (resposta.data.result != 'Não possui dados')
      ls_Resp = resposta.data.result;
  return ls_Resp;
}

export {
  ContasReceberGet
}
